import React from 'react'
import SEO from './seo'
import styled from '@emotion/styled'

const PageHeader = ({ title, children, seo }) => {
  return (
    <Header>
      {seo === true && title && <SEO title={title} />}
      <div className="container">{title && <Title>{title}</Title>}</div>
      {children && children}
    </Header>
  )
}

const Header = styled.header`
  background: linear-gradient(
    to bottom,
    ${p => p.theme.colors.tint1}8C,
    #fff 90%
  );

  padding-top: 80px;
  padding-bottom: 40px;
`

const Title = styled.h1`
  font-size: 40px;
  font-weight: 300;
  border-bottom: 2px solid ${p => p.theme.colors.primary};
  padding-bottom: 24px;
`

export default PageHeader
