import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'

const ImportantInfoPage = ({ data }) => {
  const { html } = data.content.childMarkdownRemark

  return (
    <Layout>
      <PageHeader title="Important Information" seo />
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

export default ImportantInfoPage

export const pageQuery = graphql`
  query {
    content: file(name: { eq: "important-info" }) {
      childMarkdownRemark {
        html
      }
    }
  }
`
